import { Dropdown, Tooltip } from "flowbite-react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { authService } from "common/auth/auth.service"
import { createUsersModule } from "common/users"

const ProfileMenu: React.FC<any> = () => {
    const usersModule = createUsersModule()
    const profile = useSelector(usersModule.profileSelector)
    const [fullName, setFullName] = useState<string>()

    useEffect(() => {
        // console.log(profile)
        setFullName(`${profile?.user?.first_name} ${profile?.user?.last_name} (${profile?.tenant?.name})`)
    }, [profile])
    const logout = () => {
        console.log('logout');
        authService.signout();
    }
    const switchTenant = () => {
        console.log('switch tenant...');
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('refreshToken')
        authService.signin();
    }

    return <Dropdown label={
        <Tooltip content={fullName}>
            <span className="text-sm md:text-md max-w-[100px] truncate">
                {fullName}
            </span>
        </Tooltip>} inline>
        <Dropdown.Item><div className="text-md text-[#4D5E77]"
            onClick={switchTenant}>
            Switch Tenant</div>
        </Dropdown.Item>

        <Dropdown.Item><div className="text-md text-[#FC4D66]"
            onClick={logout}>
            Logout</div>
        </Dropdown.Item>

    </Dropdown>
}

export default ProfileMenu;