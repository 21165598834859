import { useEffect, useRef, useState } from "react";
import { Spinner } from "flowbite-react";
import { ErrorToast } from "components/toasts";

const RunPrompt: React.FC<any> = ({ onChange, onRun, enabled, prompt }) => {
    const [isSubmit, setIsSubmit] = useState<boolean>()
    const [isEnabled, setIsEnabled] = useState<boolean>(false)
    const opacity = isEnabled ? '' : 'opacity-50'
    const [error, setError] = useState<string>()

    useEffect(() => {
        // console.log(`is run enabled ${enabled}`)
        // console.log(`opacity ${opacity}`)
        setIsEnabled(enabled)
    }, [enabled])

    return <div className="w-full flex flex-col gap-4">
        <textarea id="prompt" rows={3} className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 
    rounded-lg border border-gray-300 focus:ring-[#02C389] focus:border-[#02C389]
    `}
            placeholder="Enter prompt..."
            onChange={onChange}
            value={prompt}></textarea>

        <button className={`p-4 text-center text-white 
        bg-[#02C389] font-bold rounded rounded-[15px]
        space-x-2
        ${opacity}`}
            onClick={async () => {
                setIsSubmit(true)
                setIsEnabled(false)
                try {
                    await onRun();
                } catch (err: any) {
                    console.log(err);
                    if (err?.response?.status == 429) {
                        setError(`We're extremely busy at this time. 
                        Please try again later or contact administrator if the error persists.`)
                    } else if (err?.response?.status == 502) {
                        setError(`Service overloaded or not ready. 
                        Please try again later or contact administrator if the error persists.`)
                    } else {
                        setError(`Could not run prompt. Please try again or contact administrator if the error persists.`)
                    }
                }
                setIsSubmit(false)
                setIsEnabled(true)
            }
            }
            disabled={!isEnabled || isSubmit}>
            {isSubmit && <Spinner />}<span>Run Prompt</span>
        </button>
        {error && <ErrorToast message={error} onClose={() => {
            console.log('Closing error...')
            setError('')
        }} />}

    </div>

}

export default RunPrompt;