import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import React from 'react';

const AnalyticsItem: React.FC<any> = ({ title, value }) => {

    return (
        <div className='flex gap-2'>
            <div className="relative flex items-center justify-center text-white">
                <div className='border-[50%] bg-[#02C389] 
                h-[28px] w-[28px] 
                md:h-[36px] md:w-[36px] 
                rounded-full'>
                </div>
                <div className='absolute rotate-90 text-white'>
                    <ArrowRightIcon />
                </div>
            </div>
            <div className='flex flex-col'>
                <span className='text-[10px] md:text-[14px] text-[#1B2234]'>{title}</span>
                <span className='text-[14px] md:text-[18px] font-bold text-black'>{value}</span>
            </div>
        </div>
    );
};

export default AnalyticsItem;
