import { useEffect, useState } from "react";
import { Header } from "./Header"
import AuthenticationHandler from "components/AuthenticationHandler";
import { profileFetcher } from "common/api/UsersApi";
import { useSelector } from "react-redux";
import { Spinner } from "flowbite-react";
import { createUsersModule } from "common/users";

const DashboardLayout: React.FC<any> = ({ children }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const origin = queryParams.get("origin");

    const usersModule = createUsersModule()
    const profile = useSelector(usersModule.profileSelector)
    useEffect(() => {
        (async () => {
            if (!code && !origin) {
                const profile = (await profileFetcher.fetch()).data
            }
            // console.log(profile)
        })()
    }, [code, origin])
    return <div className="w-full space-y-2 bg-[#F9FDFC] pb-[200px]">
        <AuthenticationHandler />

        <div className="flex flex-wrap mx-auto max-w-[1246px] pt-4">
            <div className="w-full">
                <Header />
            </div>
            <hr className="w-full my-4 border-t border-[#E5E9EE]"></hr>
        </div>
        {
            profile ?
                <div className="w-full flex mx-auto max-w-[1246px] pt-4 bg-[#F9FDFC]">
                    {children}
                </div>
                : <div>
                    <Spinner />
                </div>
        }
    </div>
}

export default DashboardLayout;