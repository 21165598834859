import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import React from 'react';

const AnalyticsListItem: React.FC<any> = ({ title, value }) => {

    return (
        <div className='flex gap-4 w-full items-center justify-between
              border-b border-solid border-gray-300 
              h-[62px]
              md:h-[62px] p-4'>
            <span className='text-[12px] text-[#7C8CA5]'>{title}</span>
            <span className='text-[16px] font-semibold'>{value}</span>
        </div>
    );
};

export default AnalyticsListItem;
