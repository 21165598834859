import React from 'react';

const UserStatsList: React.FC<any> = ({ stats }) => {
    return (
        <div className='flex flex-col gap-4 w-full items-center
        border-b border-solid border-gray-300 max-h-[300px] overflow-scroll'>
            {stats && stats.map((userStats: any) => {
                return <div className='ps-[40px] flex gap-4 w-full items-center justify-between
                border-b border-solid border-gray-300 
                h-[62px]
                md:h-[62px] p-4'>
                    <span className='text-[14px] font-semibold'>{userStats.first_name} {userStats.last_name}</span>
                    <span className='text-[14px] font-semibold'>{userStats.total_prompts}</span>
                </div>
            })}

        </div>
    );
};

export default UserStatsList;
