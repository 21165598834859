import { dataFetcher } from "common/api"
import promptsApi from "common/api/PromptsApi"
import { RootState, store } from "store"
import { promptActions } from "store/promptsSlice"
interface PromptsModule {
    fetchTokensCount(val: string): Promise<number>;
    fetchPrompts: () => Promise<any>;
    fetchStats: () => Promise<any>;
    promptsFetcher: any;
    statsFetcher: any;
    usersStatsFetcher: any;
    paginationSelector: (state: RootState) => any;
    statsSelector: (state: RootState) => any;
    fetchNextPage: () => Promise<any>;
    deletePrompt: (prompt: any) => Promise<void>;
    updateSearchTerm: (searchTerm: string) => Promise<void>;
    updateSkip: (skip: number) => Promise<void>;
}
export const createPromptsModule = (): PromptsModule => {
    const fetchPrompts = async () => {
        const { searchTerm, skip, limit } = store.getState().prompts
        const res = await promptsApi.getPrompts(searchTerm, skip, limit)
        store.dispatch(promptActions.updateTotalCount(res.data.total_count))
        return res
    }
    const fetchStats = async () => {
        const res = await promptsApi.getStats()
        return res
    }
    const fetchUsersStats = async () => {
        const res = await promptsApi.getUsersStats()
        return res
    }
    const promptsFetcher = dataFetcher(fetchPrompts, 'prompts')
    const statsFetcher = dataFetcher(fetchStats, 'stats')
    const usersStatsFetcher = dataFetcher(fetchUsersStats, 'usersStats')
    return {
        fetchPrompts,
        fetchStats,
        promptsFetcher,
        statsFetcher,
        usersStatsFetcher,
        paginationSelector: (state: any) => state.prompts,
        statsSelector: (state: any) => state?.data?.stats,
        fetchNextPage: async () => {
            const { totalCount, skip, limit } = store.getState().prompts
            console.log('scroll prompts')
            console.log(totalCount, skip, limit)
            const nextSkip = skip + limit
            console.log(`scroll prompts, nextSkip=${nextSkip}`)

            if (nextSkip < totalCount) {
                store.dispatch(promptActions.updateSkip(nextSkip))
                console.log(`fetch global prompts, nextSkip=${nextSkip}`)
                const res = await fetchPrompts()
                return res
            } else {
                console.log('nothing to scroll...')
                return null
            }

        },
        fetchTokensCount: async (val: string): Promise<number> => {
            const res = await promptsApi.getTokensCount(val)
            return res.data?.tokens_count
        },
        updateSearchTerm: async (searchTerm: string) => {
            console.log(`Search global prompts: ${searchTerm}`)
            store.dispatch(promptActions.updateSearchTerm(searchTerm))
            store.dispatch(promptActions.updateSkip(0))
        },
        updateSkip: async (skip: number) => {
            store.dispatch(promptActions.updateSkip(skip))
        },
        deletePrompt: async (prompt: any) => {
            console.log(`Delete prompt: ${prompt}`)
            await promptsApi.deletePrompt(prompt)
        }

    }

}

