import { authService } from "common/auth/auth.service"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { AuthState } from "store/authSlice"
const AuthenticationHandler: React.FC<any> = ({ }) => {
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const origin = queryParams.get("origin");
    const { isAuthError, error } = useSelector((state: any): AuthState => state.auth)
    useEffect(() => {
        // console.log(`isAuthError = ${isAuthError}`)
        if (isAuthError || origin) {
            console.log('Authentication error...')
            console.log(error)
            const decodedToken = authService.decodeJwt(authService.getJwt())
            console.log(decodedToken)
            const tenant = decodedToken?.getTenant()
            window.localStorage.removeItem('accessToken')
            window.localStorage.removeItem('refreshToken')
            console.log('Signin...')
            authService.signin(origin, tenant)
        }
    }, [isAuthError, origin])
    useEffect(() => {
        const checkTokenExpiration = async () => {
            const jwt = authService.getJwt();
            if (jwt) {
                const decodedToken = authService.decodeJwt(jwt);
                if (decodedToken?.isExpiring()) {
                    console.log('Token is expiring. Refreshing...');
                    try {
                        await authService.refreshToken(jwt);
                    } catch (err) {
                        console.log('Error refreshing token:', err);
                    }
                } else {
                }
            }
        };

        const intervalId = setInterval(checkTokenExpiration, 60000);
        return () => {
            console.log('Stop refresh token check...')
            clearInterval(intervalId)
        };
    }, []);
    useEffect(() => {
        // console.log(`code is ${code}`);

        (async () => {
            if (code) {
                const iss = queryParams.get("iss");
                console.log(`iss is ${iss}`);
                try {
                    if (!authService.getJwt()) {
                        try {
                            await authService.getToken(code, iss)
                        } catch (err) {
                            console.log(err)
                            console.log('could not fetch token')
                        }
                    }
                    setTimeout(() => {
                        const jwt = authService.getJwt()
                        if (jwt) {
                            navigate('/dashboard')
                        } else {
                            console.log(`JWT ${jwt} not found.`)
                        }
                    }, 1000)
                } catch (err) {
                    console.log(err)
                    console.log('Could not get token.')
                }
            }
        })()
    }, [])

    return <div />
}

export default AuthenticationHandler;
