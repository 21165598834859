import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart:React.FC<any> = ({data, categories}) => {
  const chartData = {
    series: [{
      name: 'Count',
      data: data,
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: categories,
      },
    },
  } as any;

  return (
    <div className="bar-chart">
      <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
    </div>
  );
};

export default BarChart;
