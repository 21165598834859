import { createUsersModule } from "common/users"
import { useSelector } from "react-redux"
import { Dropdown, Tooltip } from "flowbite-react"
import { useEffect, useState } from "react"
import { Assistant } from "common/api/interfaces"
import { createAssistantModule } from "./modules"
import InformationIcon from "components/icons/InformationIcon"

const SelectAssistant: React.FC<any> = ({ onSelect }) => {
  const usersModule = createUsersModule()
  const profile = useSelector(usersModule.profileSelector)
  const [assistants, setAssistants] = useState<Assistant[]>(null)
  const [label, setLabel] = useState<string>('General Assistant')
  const assistantModule = createAssistantModule()
  useEffect(() => {
    let availableAssistants = profile.settings.available_assistants
    setAssistants(availableAssistants)
  }, [profile])
  const onAssistantChange = (assistant: Assistant) => {
    let assistantId = assistant.id
    if (assistant.name !== label) {
      if (assistantId === 'none') {
        assistantId = null
      }
      assistantModule.updateAssistant(assistantId, null)
      onSelect(assistant)
    }
    setLabel(assistant.name)
    console.log(assistant.name)
  }
  return assistants &&
    <div className="flex gap-2 items-center">
      <label htmlFor="assistants" className="text-lg font-bold"> Assistant</label>
      <Dropdown label={<div className="text text-md">{label}</div>} inline >
        {assistants.map((assistant) => {
          const menuItem = <Dropdown.Item key={assistant.id} onClick={() => onAssistantChange(assistant)} className="w-full">
            <div className="flex w-full justify-between items-center gap-2">
              <div className="text w-full text-left">
                {assistant.name}
              </div>
              {assistant.description && <Tooltip content={
                <div className="flex justify-start text-left">
                  <div dangerouslySetInnerHTML={{ __html: assistant.description }} />
                </div>
              } className="w-full">
                <InformationIcon />
              </Tooltip>}
            </div>
          </Dropdown.Item>
          return menuItem
        })}
      </Dropdown>
    </div>
}

export { SelectAssistant }