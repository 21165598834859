import { AxiosPromise } from 'axios'
import { createAxiosInstance, dataFetcher } from '.';
import CONFIG from 'config';
import { Profile } from './interfaces';

const UsersApi = () => {
    const axiosInstance = createAxiosInstance()
    return {
        getProfile: (): AxiosPromise<Profile> => {
            return axiosInstance.get(`${CONFIG.API_URL}/profile`)
        },
        signin: (origin?: string, tenant?: string): AxiosPromise<any> => {
            if (origin) {
                return axiosInstance.get(`${CONFIG.API_URL}/auth?origin=${origin}`)
            } else {
                if (tenant) {
                    return axiosInstance.get(`${CONFIG.API_URL}/auth?tenant=${tenant}`)
                }
                return axiosInstance.get(`${CONFIG.API_URL}/auth`)
            }
        },
        signout: (): AxiosPromise<any> => {
            return axiosInstance.get(`${CONFIG.API_URL}/signout`)
        },
        getToken: (code: string, iss: string): AxiosPromise<any> => {
            return axiosInstance.get(`${CONFIG.API_URL}/token?code=${code}&iss=${iss}`)
        },
        refreshToken: (accessToken: any, refreshToken: string): AxiosPromise<any> => {
            return axiosInstance.post(`${CONFIG.API_URL}/refresh-token`, {
                access_token: accessToken,
                refresh_token: refreshToken
            })
        },

    }
}

const usersApi = UsersApi()
const fetchProfile = async () => {
    return await usersApi.getProfile()
}
const profileFetcher = dataFetcher(fetchProfile, 'profile')
export { profileFetcher };
export default usersApi