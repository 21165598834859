import { Spinner } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import PaginationInfo from "./PaginationInfo";
import { useSelector } from "react-redux";

const InfiniteScroll: React.FC<any> = ({ children, fetchData, className, paginationSelector }) => {
    const observerTarget = useRef(null);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState()
    const { totalCount, skip, limit } = useSelector(paginationSelector) as any

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    (async () => {
                        // console.log('scrolling...')
                        setIsLoading(true)
                        try{
                            await fetchData()
                        }catch(err){
                            console.log(err)
                        }
                        setIsLoading(false)
                    })()
                }
            },
            { threshold: 1 }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [observerTarget]);
    return <div className="relative">
        <div className={className}>
            {children}
            {/* {isLoading && <p>Loading...</p>} */}
            {error && <p>Error: {error}</p>}
            <div ref={observerTarget}></div>
        </div>
        {paginationSelector &&
            <PaginationInfo
                totalCount={totalCount}
                skip={skip}
                limit={limit}
            />
        }
        {isLoading && (
            <div className="absolute inset-0 pe-2 flex items-center justify-center bg-white opacity-50 z-10">
                <Spinner color="success" />
            </div>
        )}
    </div>
}

export default InfiniteScroll;