import { useEffect, useState } from 'react';
import DashboardLayout from '../components/layouts/DashboardLayout';
import { Spinner } from 'flowbite-react';
import { useSelector } from 'react-redux';
import { createPromptsModule } from 'components/prompts/modules';
import { ErrorToast, SuccessToast } from 'components/toasts';
import AnalyticsItem from 'components/analytics/AnalyticsItem';
import AnalyticsListItem from 'components/analytics/AnalyticsListItem';
import humanizeNumber from 'humanize-number';
import { humanizeDuration } from 'common/time';
import UserStatsList from 'components/analytics/UserStatsList';
import ArrowDownIcon from 'components/icons/ArrowDownIcon';
import ArrowUpIcon from 'components/icons/ArrowUpIcon';

const AnalyticsIcon = () => {
  return <svg width="19" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"
    className="sc-jWUzTF bflNhW"><path d="M.62 10.849h2v9h-2v-9zm16-4h2v13h-2v-13zm-8-6h2v19h-2v-19z"
      fill="#4E5F77"></path></svg>
}
interface Stats {
  totalTokens: string;
  totalPrompts: string;
  totalGlobalPrompts: string;
  totalUsers: string;
  totalTenants: string;
  totalDuration: string;
  estimatedCost: string;
  avgDuration: string;
  monthToDatePrompts: string;
  monthToDateTokens: string;
  monthToDateEstimatedCost: string;

}
interface UserStats {
  username: string;
  total_prompts: number;
  first_name?: string | null;
  last_name?: string | null;
}
function AnalyticsPage() {
  const [loading, setLoading] = useState<boolean>(true)
  const profileState = useSelector((state: any) => state.data.profile)
  const profile = profileState?.result
  const promptsModule = createPromptsModule()
  const statsState = useSelector(promptsModule.statsSelector)
  const [stats, setStats] = useState<Stats>()
  const [usersStats, setUsersStats] = useState<UserStats[]>()
  const [showUserStats, setShowUserStats] = useState<boolean>(false)
  useEffect(() => {
    const loadData = async () => {
      promptsModule.statsFetcher.fetch()
      const res = await promptsModule.usersStatsFetcher.fetch()
      setUsersStats(res.data)
    }

    profile && loadData()

  }, [profile])

  useEffect(() => {
    if (statsState && statsState.result) {
      const result = statsState.result
      const stats = {
        totalTokens: humanizeNumber(result.total_tokens),
        totalUsers: humanizeNumber(result.total_users),
        totalTenants: humanizeNumber(result.total_tenants),
        totalPrompts: humanizeNumber(result.total_prompts),
        totalGlobalPrompts: humanizeNumber(result.total_global_prompts),
        estimatedCost: humanizeNumber(Math.round(result.estimated_cost)),
        totalDuration: humanizeDuration(result.total_duration),
        avgDuration: humanizeNumber(Math.round(result.total_duration / 1000 / result.total_prompts)),
        monthToDatePrompts: humanizeNumber(result.month_to_date_prompts),
        monthToDateTokens: humanizeNumber(result.month_to_date_tokens),
        monthToDateEstimatedCost: humanizeNumber(Math.round(result.month_to_date_estimated_cost)),

      }
      setStats(stats)
    }
  }, [statsState])

  const handleShowUserStats = () => {
    setShowUserStats(!showUserStats)
  }

  return (
    <div className="p-4 flex justify-center">
      <DashboardLayout>
        {statsState?.isLoading || !stats ? <Spinner />
          :
          <div className='flex flex-col gap-4 w-full justify-center items-center'>
            <div className='flex justify-center w-full'>
              <div className='flex w-full justify-between items-center max-w-[700px]'>
                <AnalyticsItem
                  title="Total Tokens"
                  value={stats.totalTokens} />
                <div className='bg-[#EFF2F6] h-full w-[2px]' />
                <AnalyticsItem
                  title="Total Prompts"
                  value={stats.totalPrompts} />
                <div className='bg-[#EFF2F6] h-full w-[2px]' />
                <AnalyticsItem
                  title="Estimated Cost"
                  value={`$${stats.estimatedCost}`} />
              </div>
            </div>
            <div className='shadow flex flex-col max-w-[700px] w-full'>
              <div className='flex gap-4 w-full items-center
              border-b border-solid border-gray-300 
              h-[62px]
              md:h-[62px] p-4'>
                <AnalyticsIcon />
                <span className='text-lg font-bold'>Analytics</span>
              </div>
              <button
                onClick={handleShowUserStats}>
                <AnalyticsListItem
                  title={
                    <div className='flex gap-2 items-center'>
                      Total Users
                      {showUserStats ? <ArrowDownIcon color="#02C389" /> : <ArrowUpIcon color="#02C389" />}
                    </div>
                  }
                  value={stats.totalUsers}
                />
              </button>
              {showUserStats && usersStats && <UserStatsList stats={usersStats} />}
              <AnalyticsListItem
                title="Total Tenants"
                value={stats.totalTenants}
              />
              <AnalyticsListItem
                title="Total Global Prompts"
                value={stats.totalGlobalPrompts}
              />
              <AnalyticsListItem
                title="Avg Prompt Duration"
                value={`${stats.avgDuration} seconds`}
              />
              <AnalyticsListItem
                title="Month to Date Tokens"
                value={stats.monthToDateTokens}
              />
              <AnalyticsListItem
                title="Month to Date Estimated Cost"
                value={`$${stats.monthToDateEstimatedCost}`}
              />
              <AnalyticsListItem
                title="Month to Date Prompts"
                value={stats.monthToDatePrompts}
              />

            </div>
          </div>
        }
      </DashboardLayout>

    </div>
  );
}

export default AnalyticsPage;
