import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DataState {
  result: any | null,
  isLoading: boolean;
  error: string | null;
}


const initialState: Record<string, DataState> | null = {

};

const apiDataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    dataRequest: (state, action: PayloadAction<string>) => {
      const guid = action.payload
      state[guid] = {
        isLoading: true,
        error: null,
        result: null
      }
    },
    dataSuccess: (state, action: PayloadAction<any>) => {
      const guid = action.payload.guid
      state[guid] = {
        isLoading: false,
        error: null,
        result: action.payload.result
      }
    },
    dataFailure: (state, action: PayloadAction<any>) => {
      const guid = action.payload.guid
      state[guid] = {
        isLoading: false,
        error: action.payload.error,
        result: null
      }
    },
  },
});

export const { dataRequest, dataSuccess, dataFailure } = apiDataSlice.actions;

export default apiDataSlice.reducer;