import React from 'react';

const PaginationInfo: React.FC<any> = ({ totalCount, skip, limit }) => {
    const start = skip + 1
    const end = Math.min(skip + limit, totalCount)
    if (totalCount === 0) {
        return <div className="text-sm">No data</div>
    }
    return (
        <div className="flex justify-between items-center">
            <div className="text-sm">
                Showing <strong>{end}</strong> of <strong>{totalCount}</strong> Entries
            </div>
        </div>
    );
};

export default PaginationInfo;
