
/* eslint-disable @typescript-eslint/no-explicit-any */
import usersApi from '../api/UsersApi';
import { jwtDecode } from 'jwt-decode';
interface AuthService {
  decodeJwt(token: string): Token;
  signin(origin?: string, tenant?: string): Promise<void>;
  signout(): Promise<void>;
  getToken(code: string, iss: string): Promise<string>;
  refreshToken(accessToken: string): Promise<any>;
  getJwt(): null | undefined | string;
  isTokenExpiring(token: Token): null | undefined | boolean;
}

interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}
export class Token {
  aud: string;
  client_id: string;
  exp: number;
  grantId: string;
  iat: number;
  iss: string;
  jti: string;
  scope: string;
  sub: string;
  tenant_id: number;

  constructor(decodedToken: any) {
    this.aud = decodedToken.aud;
    this.client_id = decodedToken.client_id;
    this.exp = decodedToken.exp;
    this.grantId = decodedToken.grantId;
    this.iat = decodedToken.iat;
    this.iss = decodedToken.iss;
    this.jti = decodedToken.jti;
    this.scope = decodedToken.scope;
    this.sub = decodedToken.sub;
    this.tenant_id = decodedToken.tenant_id;
  }

  getTenant(): string {
    const user = this.sub;
    const match = this.scope.match(/tenant:(\w+)/);
    const tenant = match ? match[1] : null;
    return tenant
  };

  isExpiring(): boolean {
    const now = new Date();
    const currentTime = Math.floor(now.getTime() / 1000);
    const threshold = 5 * 60;
    const isExpiring = this.exp - currentTime < threshold;
    return isExpiring ? true : false;

  }
}


const authService: AuthService = {
  signin: async (origin?: string, tenant?: string) => {
    const redirectUrl = (await usersApi.signin(origin, tenant)).data
    console.log(redirectUrl)
    window.location.href = redirectUrl
  },
  signout: async () => {
    window.localStorage.removeItem('accessToken')

    const rediredtUrl = (await usersApi.signout()).data
    console.log(rediredtUrl)
    window.location.href = rediredtUrl
  },
  getToken: async (code: string, iss: string) => {
    const data = (await usersApi.getToken(code, iss)).data
    const accessToken = data?.access_token
    const refreshToken = data?.refresh_token
    window.localStorage.setItem('accessToken', accessToken)
    window.localStorage.setItem('refreshToken', refreshToken)
    return accessToken
  },
  getJwt: () => {
    return window.localStorage.getItem('accessToken')
  },
  decodeJwt: (token) => {
    try {
      return new Token(jwtDecode(token))
    } catch (err) {
      console.log('Could not decode token.')
      console.log(err)
      return null
    }
  },
  isTokenExpiring: (token: Token) => {
    return token?.isExpiring() ? true : false;
  },
  refreshToken: async (accessToken: string) => {
    const refreshToken = window.localStorage.getItem('refreshToken')
    const data = (await usersApi.refreshToken(accessToken, refreshToken)).data as any
    const newAccessToken = data?.access_token;
    const newRefreshToken = data?.refresh_token;
    if (newAccessToken && newRefreshToken) {
      window.localStorage.setItem('accessToken', newAccessToken)
      window.localStorage.setItem('refreshToken', newRefreshToken)
      return { accessToken: newAccessToken, refreshToken: newRefreshToken } as RefreshTokenResponse;
    }
    return null
  },

}
export { authService };
