const UploadFileIcon = () => {
    return <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"
        className="sc-DtlDN hEyqHM">
        <path d="M.766 10.395h10.388v1.154H.766v-1.154zm5.771-7.603V9.24H5.383V2.792L1.879 6.297l-.816-.816L5.96.583l4.897 4.897-.816.816-3.504-3.502v-.002z"
            fill="#fff">
        </path>
    </svg>
}

export default UploadFileIcon;
