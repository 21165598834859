import { useEffect, useState } from 'react';
import promptsApi, { globalPromptsFetcher } from '../common/api/PromptsApi';
import DashboardLayout from '../components/layouts/DashboardLayout';
import ContextData from 'components/prompts/ContextData';
import GlobalPrompts from 'components/prompts/GlobalPrompts';
import RunPrompt from 'components/prompts/RunPrompt';
import PromptsHistory from 'components/prompts/PromptsHistory';
import { useSelector } from 'react-redux';
import { createPromptsModule } from 'components/prompts/modules';
import { authService } from 'common/auth/auth.service';
import { createUsersModule } from 'common/users';
import { SelectAssistant } from 'components/assistants/SelectAssistant';
import { createAssistantModule } from 'components/assistants/modules';
import { AssistantState } from 'store/assistantSlice';
import { Assistant } from 'common/api/interfaces';


function DashboardPage() {
  const usersModule = createUsersModule()
  const assistantModule = createAssistantModule()

  const profile = useSelector(usersModule.profileSelector)
  const assistant = useSelector(assistantModule.assistantSelector) as AssistantState
  const [prompt, setPrompt] = useState<string>()
  const [isRunEnabled, setIsRunEnabled] = useState<boolean>(false)
  const [contextData, setContextData] = useState<string>()
  const [tokensCount, setTokensCouunt] = useState<number>()
  const [contentType, setContentType] = useState<string>('text')
  const promptsModule = createPromptsModule()

  useEffect(() => {
    const loadData = async () => {
      globalPromptsFetcher.fetch()
      promptsModule.promptsFetcher.fetch()
    }

    profile && loadData()

  }, [profile])


  const onContextDataChange = (e: any) => {
    // console.log(e)
    const val = e.target.value
    setContextData(val)
    setIsRunEnabled(prompt && val !== undefined && val.trim() !== '')
    const assistantId = assistant && assistant.assistantId
    assistantModule.updateAssistant(assistantId, null)
    console.log('context data changed')
  }
  const onPromptChange = (e: any) => {
    // console.log(e)
    const val = e.target.value
    setPrompt(val)
    setIsRunEnabled(contextData && val !== undefined && val.trim() !== '')
    console.log('prompt data changed')
  }
  const onPromptRun = async (e: any) => {
    console.log('prompt run');
    const token = authService.getJwt()
    const assistantId = assistant && assistant.assistantId
    let threadId = assistant && assistant.threadId

    if (token) {
      const decodedToken = authService.decodeJwt(token)
      if (decodedToken?.isExpiring()) {
        console.log('Refresh token...')
        await authService.refreshToken(token)
      }
    }
    const res = await promptsApi.runPrompt(prompt, contextData, profile.user.username, contentType, profile.settings.model_name, assistantId, threadId)
    threadId = res.data?.prompt?.thread_id
    assistantModule.updateAssistant(assistantId, threadId)
    promptsModule.promptsFetcher.fetch()
    promptsModule.updateSkip(0)
  }
  const onUserRating = async (prompt: any, rating: number) => {
    await promptsApi.updatePrompt({ ...prompt, rating })
    await globalPromptsFetcher.fetch()
  }
  const onContextDataFilesUpload = async (selectedFiles: any[]) => {
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]);
    }
    const res = (await promptsApi.uploadContextFiles(formData)).data
    setContextData(res.context_data)
    setContentType(res.content_type || 'text')
    setTokensCouunt(res.tokens_count)
  }
  const onGlobalPromptSelect = async (prompt: any) => {
    console.log(`selected prompt ${prompt.query}`)
    setPrompt(prompt.query)
    if (contextData) {
      setIsRunEnabled(true)
    } else {
      setIsRunEnabled(false)
    }
  }
  return (
    <div className="p-4 flex justify-center">
      <DashboardLayout>
        <div className='w-full flex flex-col gap-4'>
          <div className='w-full flex justify-end'>
            <SelectAssistant onSelect={(assistant: Assistant) => {
              const recommendedPrompt = assistant.recommended_prompt || ''
              setPrompt(recommendedPrompt)
              if (contextData && recommendedPrompt) {
                setIsRunEnabled(true)
              } else {
                setIsRunEnabled(false)
              }
            }} />
          </div>
          <div className='flex flex-wrap md:flex-nowrap justify-between w-full gap-8'>
            <ContextData onChange={onContextDataChange}
              onUpload={onContextDataFilesUpload}
              contextData={contextData}
              tokensCount={tokensCount}
            />
            <GlobalPrompts
              onSelect={onGlobalPromptSelect}
            />
          </div>
          <RunPrompt
            onChange={onPromptChange}
            onRun={onPromptRun}
            enabled={isRunEnabled}
            prompt={prompt}
          />
          <PromptsHistory
            onRating={onUserRating}
          />
        </div>
      </DashboardLayout>

    </div>
  );
}

export default DashboardPage;
