import UploadFileIcon from "components/icons/UpoloadFileIcon"
import { ErrorToast } from "components/toasts"
import { Spinner } from "flowbite-react"
import { useEffect, useRef, useState } from "react"
import { createPromptsModule } from "./modules"
import { debounce } from "lodash"

const ContextData: React.FC<any> = ({ onChange, onUpload, contextData, tokensCount }) => {
    const [isSubmit, setIsSubmit] = useState<boolean>()
    const [isEnabled, setIsEnabled] = useState<boolean>(true)
    const [error, setError] = useState<string>()
    const opacity = isEnabled ? '' : 'opacity-50'
    const fileInputRef = useRef<HTMLInputElement>(null);
    const promptsModule = createPromptsModule()
    const [contextDataTokensCount, setContextDataTokensCount] = useState<number>()

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };
    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;
        setIsSubmit(true);
        setIsEnabled(false);
        try {
            await onUpload(selectedFiles);
        } catch (err: any) {
            console.log(err)
            setError(err?.response?.data?.error || 'Could not upload data. Please contact admin if the error persists.')
        }
        setIsSubmit(false);
        setIsEnabled(true);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }
    const fetchTokensCountDebounced = useRef(
        debounce(async (value: string) => {
            const tokensCount = await promptsModule.fetchTokensCount(value);
            return tokensCount;
        }, 500)
    ).current;

    const handleContextDataChange = async (e: any) => {
        const val = e.target.value
        onChange(e)
        setContextDataTokensCount(await fetchTokensCountDebounced(val))
    }

    useEffect(() => {
        setContextDataTokensCount(tokensCount)
    }, [tokensCount])

    return <div className="w-full">
        <div className="w-full flex justify-between p-2">
            <span className="text font-bold text-sm md:text-xl p-1 uppercase">Context Data</span>
            <button className={`
            p-1 min-w-[160px]
            md:p-2 text-center flex justify-center items-center text-white text-[14px]
            md:min-w-[215px]
        bg-[#02C389] font rounded rounded-[15px]
        space-x-2
        ${opacity}`}
                onClick={handleButtonClick}
                disabled={!isEnabled || isSubmit}>
                {isSubmit && <Spinner />} <UploadFileIcon /><span>Upload</span>
                <input id="file-upload" type="file"
                    onChange={handleUpload}
                    ref={fileInputRef}
                    multiple
                    accept=".txt,.csv"
                    className="hidden" />
            </button>
        </div>
        {/* <label htmlFor="context-data" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter context data...</label> */}
        <textarea id="context-data" rows={14}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 mb-2
    rounded-lg border border-gray-300 focus:ring-[#02C389] focus:border-[#02C389]
    "
            placeholder="Enter context data..."
            onChange={handleContextDataChange}
            value={contextData}
        ></textarea>
        <div className="w-full flex justify-between items-center">
            <div className=""></div>
            {/* <button className="text-sm md:text-md p-1 md: p-2 border border-[#D1D5DA] rounded rounded-md">
                Reset
            </button> */}
            {
                contextDataTokensCount ?
                <div className="text-sm">
                    <strong>{contextDataTokensCount}</strong> tokens
                </div>
                : <div></div>
            }

        </div>

        {error && <ErrorToast message={error} onClose={() => { setError('') }} />}
    </div>


}

export default ContextData;