import { createUsersModule } from "common/users";
import AnalyticsPage from "pages/Analytics";
import DashboardPage from "pages/Dashboard";
import RefreshTokenPage from "pages/RefreshToken";
import { useSelector } from "react-redux";
import { Routes } from "react-router";
import { BrowserRouter, Route } from "react-router-dom";
import { UserbackProvider } from "@userback/react";
import CONFIG from "config";

function App() {
  const usersModule = createUsersModule();
  const profile = useSelector(usersModule.profileSelector);

  return (
    <div className="App">
      <UserbackProvider
        token={CONFIG.USERBACK_TOKEN}
        options={{
          name: `${profile?.user?.first_name} ${profile?.user?.last_name}`,
          email: profile?.user?.email,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/dashboard" Component={DashboardPage} />
            <Route path="/refresh-token" Component={RefreshTokenPage} />
            <Route path="/analytics" Component={AnalyticsPage} />
            <Route path="/" Component={DashboardPage} />
          </Routes>
        </BrowserRouter>
      </UserbackProvider>
    </div>
  );
}
export default App;
