import { RootState, store } from "store"
import { assistantActions, AssistantState } from "store/assistantSlice"
interface AssistantModule {
  updateAssistant: (assistantId: string | null, threadId: string | null) => Promise<void>;
  assistantSelector: any
}
export const createAssistantModule = (): AssistantModule => {

  return {
    updateAssistant: async (assistantId: string | null, threadId: string | null) => {
      console.log(`Updating assistant(assistantId=${assistantId},threadId=${threadId})`)
      store.dispatch(assistantActions.updateAssistant({ assistantId, threadId }))
    },
    assistantSelector: (state: RootState) => state.assistant as AssistantState
  }

}

