import { Table } from 'flowbite-react';
import React from 'react';


const DataTable: React.FC<any> = ({ data }) => {
    // console.log(data)
    const [headers, ...rows] = Array.isArray(data?.table) ? data?.table : []
    return headers && <div className='w-full'>
        <Table>
            <Table.Head>
                {headers?.map((header: any) => {
                    return <Table.HeadCell key={header}>{header}</Table.HeadCell>
                })}

            </Table.Head>
            <Table.Body className="divide-y">
                {rows?.map((row: any, rowIndex: number) => {
                    return <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        key={`row-${rowIndex}`}>
                        {row.map((cell: any, cellIndex: number) => {
                            return <Table.Cell key={`cell-${rowIndex}-${cellIndex}`}>{cell}</Table.Cell>
                        })}
                    </Table.Row>
                })}

            </Table.Body>
        </Table>
    </div>
}

export default DataTable;
