import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CONFIG from 'config';

export interface GlobalPromptsState {
  totalCount: number;
  skip: number;
  limit: number;
  searchTerm: string | null;
}

const initialState: GlobalPromptsState = {
  totalCount: 0,
  skip: 0,
  limit: CONFIG.GLOBAL_PROMPTS_LIMIT,
  searchTerm: null,
};

const slice = createSlice({
  name: 'globalPrompts',
  initialState,
  reducers: {
    updateSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    updateTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload;
    },
    updateSkip(state, action: PayloadAction<number>) {
      state.skip = action.payload;
    },
  },
});

export const { updateSearchTerm, updateTotalCount, updateSkip } = slice.actions;

export default slice.reducer;

