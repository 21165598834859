import axios, { AxiosInstance, AxiosPromise } from 'axios';
import { store } from 'store';
import { dataFailure, dataRequest, dataSuccess } from 'store/apiDataSlice';
import { authFailed } from 'store/authSlice';
const API_URL = process.env.REACT_APP_API_URL;
const createAxiosInstance = () => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    const _axiosInstance: AxiosInstance = axios.create({
        baseURL: API_URL,
        headers: headers,
        responseType: 'json'
    });

    _axiosInstance.interceptors.request.use((config) => {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    });

    _axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log('Unauthorized request. Redirect or refresh token logic goes here.');
                store.dispatch(authFailed({ error: error.response?.data?.error }));
                return new Promise((resolve) => {
                    setTimeout(() => {
                        console.log('Waiting for redirect...');
                    }, 30000);
                });
            } else {
                return Promise.reject(error);
            }
        }
    );

    return _axiosInstance;
};


const dataFetcher = (fetcher: any, guid: string) => {
    return {
        fetch: async () => {
            try {
                store.dispatch(dataRequest(guid));
                const response = await fetcher()
                store.dispatch(dataSuccess({ guid, result: response.data }));
                return response
            } catch (error: any) {
                console.log(error)
                store.dispatch(dataFailure({ guid, error: error.message }));
                throw error
            }
        }
    }
}

export { createAxiosInstance, dataFetcher }