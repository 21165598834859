import { useEffect, useState } from 'react';
import { Token, authService } from 'common/auth/auth.service';
import DataTable from 'components/DataTable';
import { ErrorToast } from 'components/toasts';
function RefreshTokenPage() {
  const [error, setError] = useState<string>()
  const [claimData, setClaimData] = useState<any>()
  const [decodedToken, setDecodedToken] = useState<Token>()
  const getClaimData = (decodedToken: Token) => {
    const issuedAt = new Date(decodedToken.iat * 1000);
    const expiration = new Date(decodedToken.exp * 1000);
    const isTokenExpiring = authService.isTokenExpiring(decodedToken)
    const data = [
      ['Name', 'Value'],
      ['Scope', decodedToken.scope],
      ['Issued At', issuedAt.toISOString()],
      ['Epires At', expiration.toISOString()],
      ['Is Token Expiring', isTokenExpiring ? 'True' : 'False'],
      ['Current Time', new Date().toISOString()],
      ['User Id', decodedToken.sub],
      ['Tenant Id', decodedToken.tenant_id],
    ]
    return data
  }
  useEffect(() => {
    const accessToken = authService.getJwt()
    if (accessToken) {
      const decodedToken = authService.decodeJwt(accessToken)
      if (decodedToken) {
        setDecodedToken(decodedToken)
        setClaimData({
          table: getClaimData(decodedToken)
        })
      }
    }
  }, [])


  const handleRefreshToken = async () => {
    console.log('Refresh token...')
    if (decodedToken) {
      try {
        const res = await authService.refreshToken(authService.getJwt())
        const newDecodedToken = authService.decodeJwt(res.accessToken)
        setDecodedToken(newDecodedToken)
        setClaimData({
          table: getClaimData(newDecodedToken)
        })
        setError('')
      } catch (err) {
        console.log('Could not refresh token.')
        console.log(err)
        setError('Could not refresh token.')
      }
    } else {
      console.log('No decoded token.')
    }
  }

  return claimData && (
    <div className="p-4 flex flex-col gap-2 justify-center">
      <DataTable data={claimData} />
      <button className={`
            p-1 min-w-[160px] max-w-[160px]
            md:p-2 text-center flex justify-center items-center text-white text-[14px]
            md:min-w-[215px] md:max-w-[215px]
        bg-[#02C389] font rounded rounded-[15px]
        space-x-2
        `}
        onClick={handleRefreshToken}
      >
        Refresh Token
      </button>
      {error && <ErrorToast message={error} onClose={() => { setError('') }} />}
    </div>
  );
}

export default RefreshTokenPage;
