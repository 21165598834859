const SearchIcon = () => {
    return <svg className="w-3 h-3 text-gray-500 dark:text-gray-400"
        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
        <path stroke="currentColor"
            strokeLinecap="round" strokeLinejoin="round"
            strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z">
        </path>
    </svg>

}

export default SearchIcon;