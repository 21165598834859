import { ChangeEvent, useEffect, useState } from "react";
import SearchIcon from "./icons/SearchIcon";
import { Spinner, TextInput } from "flowbite-react";

const SearchInput: React.FC<any> = ({ onSearch }) => {
    const [disabled, setDisabled] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>()
    const [isClear, setIsClear] = useState<boolean>(false)
    const handleSearch = (e: any) => {
        const val = e.target.value;
        // console.log(`Search input changed ${val}`);
        setSearchTerm(val)
        setIsClear(searchTerm && !val)
    }

    useEffect(() => {
        const search = async () => {
            setDisabled(true)
            await onSearch(searchTerm);
            setDisabled(false)
        }
        isClear && search()
    }, [isClear])
    const handleKeyDown = async (e: any) => {
        // console.log(e)
        if (e.key === 'Enter') {
            setDisabled(true)
            await onSearch(searchTerm);
            setDisabled(false)
        }
    }
    return <div className="relative">
        <TextInput
            id="search"
            color="success"
            className=""
            placeholder="Search..."
            required
            rightIcon={!disabled && SearchIcon}
            type="search"
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
            disabled={disabled}
        />
        {disabled && (
            <div className="absolute inset-0 pe-2 flex items-center justify-end bg-transparent bg-opacity-75 z-10">
                <Spinner color="success" />
            </div>
        )}
    </div >
}

export default SearchInput;