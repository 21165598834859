import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AssistantState {
  assistantId?: string | null;
  threadId?: string | null;
}

const initialState: AssistantState = {
  assistantId: null,
  threadId: null,
};

const slice = createSlice({
  name: 'assistant',
  initialState,
  reducers: {
    updateAssistant(state, action: PayloadAction<AssistantState>) {
      state.assistantId = action.payload.assistantId;
      state.threadId = action.payload.threadId;
    },
  },
});

export const assistantActions = slice.actions;

export default slice.reducer;

