import React from 'react';

interface HighlightTextProps {
    text: string;
    searchTerm: string;
}

const HighlightText: React.FC<HighlightTextProps> = ({ text, searchTerm }) => {
    const lowerSearchTerm = searchTerm?.toLowerCase();
    const parts = text?.split(new RegExp(`(${searchTerm})`, 'gi'));

    return searchTerm && parts ? (
        <p className='m-0 whitespace-pre-wrap'>
            {parts.map((part, index) => {
                const isMatch = part.toLowerCase() === lowerSearchTerm
                const cn = isMatch ? 'bg-[#02C389] text-white rounded rounded-md' : ''
                return (
                    <span
                        key={index}
                        className={`p-0  ${cn}`}
                    >
                        {part}
                    </span>
                )
            })}
        </p>
    ) : (<p className='m-0 whitespace-pre-wrap'>{text}</p>);
};

export default HighlightText;
