const ArrowUpIcon: React.FC<any> = ({ color }) => {
    return <svg width="11" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"
        className="sc-jvrRIR eYABDx transform rotate-180">
        <path d="M5.86 6.375l-4.625-4.594c-.187-.187-.187-.364 0-.531l.594-.625c.187-.167.364-.167.531 0L5.61 4.344 9.36.625c.167-.167.344-.167.531 0l.594.625c.188.167.188.344 0 .531L5.36 6.375c-.167.167-.333.167-.5 0z"
            fill={color || "#7c8da5"} />
    </svg>

}

export default ArrowUpIcon;
