import CloseIcon from "../icons/CloseIcon"
import { Toast } from "flowbite-react"

const ErrorToast: React.FC<any> = ({ message, onClose }) => {
  return <Toast className="fixed bottom-5 left-0 
  max-w-xs w-full flex items-center justify-center
  min-w-[800px] z-50 bg-white">
    <div onClick={onClose}
      className="inline-flex h-8 w-8  items-center justify-center 
        rounded-lg bg-red-400 text-red-500">
      <CloseIcon />
    </div>
    <div className="ml-3 text-sm font-normal min-w-[600px]">
      {message}
    </div>
    {/* <Toast.Toggle /> */}
  </Toast>
}
const SuccessToast: React.FC<any> = ({ message, onClose }) => {
  return <div className="fixed bottom-5 left-0 w-full flex items-center justify-center">
    <Toast className=" 
  min-w-full md:min-w-[800px] z-50 bg-white">
      <div onClick={onClose}
        className="inline-flex h-8 w-8  items-center justify-center 
        rounded-lg bg-green-400 text-[#31D582]">
        <CloseIcon />
      </div>
      <div className="ml-3 text-sm font-normal min-w-[300px] md:min-w-[600px]">
        {message}
      </div>
      {/* <Toast.Toggle /> */}
    </Toast>

  </div>
}

export { ErrorToast, SuccessToast };