
const DeleteIcon = () => {
    return <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg" id="delete-icon"
        className="sc-fTQuIj fxTeMH">
        <path d="M8.204 2.782v6.29a.45.45 0 01-.45.449H1.466a.45.45 0 01-.45-.45v-6.29H.118v-.898h8.986v.899h-.899zM4.161 4.129v3.145h.898V4.13h-.898zM2.364.535h4.492v.899H2.364V.535z"
            fill="#FC4D66">
        </path>
    </svg>


}

export default DeleteIcon;